import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import Quote from '../../components/Quote';
import Hero from '../../components/Hero';
import Resource from '../../components/Resource';

const IndexPage = ({ data }) => (
  <Layout title="STEM Careers">
    <Hero
      mediaSource={'STEM-image-hero.jpg'}
      className="stem-careers__hero"
      textOverlay={
        <div>
          <p style={{ visibility: 'hidden' }}>test</p>
        </div>
      }
    />
    <Section className={'pt-1'}>
      <Container>
        <Row>
          <h2>How STEM Careers Improve Indoor Climate</h2>
          <p>
            Take students inside the world of Trane Technologies, where
            employees across various fields work to create comfortable,
            energy-efficient indoor environments. Check out a series of
            interactive career profiles featuring videos and companion student
            activations.
          </p>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Column size={4}>
            <Resource
              img="STEM-image-CP-HVAC.jpg"
              subject="HVAC Technician"
              title="Meet Earnell Newman"
              description="Explore the everyday troubleshooting and preventative maintenance that ensures indoor environments maintain safe and healthy air quality."
              actions={[
                {
                  label: 'Learn More',
                  link: '/career-profiles/hvac-tech',
                },
                {
                  label: 'Supplemental Resources',
                  dropdown: true,
                  actions: [
                    {
                      label: 'Career Profiles',
                      download:
                        '/pdfs/SustainableFutures-CareerProfile-HVACTechnician.pdf',
                      asLink: true,
                    },
                    {
                      label: 'Student Activation',
                      download:
                        '/pdfs/SustainableFutures-CareerActivation-HVACTechnician.pdf',
                      asLink: true,
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img="STEM-image-CP-DirInnov.jpg"
              subject="Director of Innovation"
              title="Meet Jason Tilghman"
              description="Look ahead to the future of HVAC technology and learn how innovations are embraced by companies to maximize efficiency and success."
              actions={[
                {
                  label: 'Learn More',
                  link: '/career-profiles/director-of-innovation',
                },
                {
                  label: 'Supplemental Resources',
                  dropdown: true,
                  actions: [
                    {
                      label: 'Career Profiles',
                      download:
                        '/pdfs/SustainableFutures-CareerProfile-DirInnovation.pdf',
                      asLink: true,
                    },
                    {
                      label: 'Student Activation',
                      download:
                        '/pdfs/SustainableFutures-CareerActivation-DirInnovation.pdf',
                      asLink: true,
                    },
                  ],
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img="STEM-image-CP-ProdDevEngnr.jpg"
              subject="Product Development Engineer"
              title="Meet Ana Llanos"
              description="Dive into the dynamic teamwork that goes into the product development lifecycle with an expert in collaboration and problem-solving."
              actions={[
                {
                  label: 'Learn More',
                  link: '/career-profiles/product-development-engineer',
                },
                {
                  label: 'Supplemental Resources',
                  dropdown: true,
                  actions: [
                    {
                      label: 'Career Profiles',
                      download:
                        '/pdfs/SustainableFutures-CareerProfile-ProdDevEng.pdf',
                      asLink: true,
                    },
                    {
                      label: 'Student Activation',
                      download:
                        '/pdfs/SustainableFutures-CareerActivation-ProdDevEng.pdf',
                      asLink: true,
                    },
                  ],
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <h2>Digital Lesson Bundle</h2>
          <p>
            Explore the ways that students’ environment impacts their lives with
            this hands-on learning exploration. Students will be introduced to
            Trane Technologies' Center for Healthy and Efficient Spaces (CHES)
            as they learn how to evaluate the environmental quality of the
            indoor spaces in their lives.
          </p>
        </Row>
        <Row>
          <Column size={8}>
            <Resource
              img="STEM-image-DLB-trees.jpg"
              subject="Healthy Spaces"
              audience="Middle & High School"
              duration="Duration or Length"
              description="Inspire students to investigate Indoor Environmental Quality (IEQ) in their own community. After learning the meaning of healthy spaces, students will apply what they’ve learned to an indoor space in their school through a persuasive writing challenge."
              actions={[
                {
                  label: 'Digital Lesson',
                  link: '/pdfs/Trane-DLB-HealthySpaces-V2.pptx',
                  metadata: '.PPT 95MB',
                },
              ]}
            />
          </Column>
          <Column size={4}>
            <Resource
              img="STEM-graphic-DLB-EdGuide.png"
              subject="Educator Guide"
              title="Does indoor environmental quality impact your learning environment?"
              description="Get helpful tools and strategies for implementing the Healthy Spaces lesson in your class with this comprehensive guide, including key background info, standards alignment, video transcript and more."
              actions={[
                {
                  label: 'Educator Guide',
                  download:
                    '/pdfs/SustainableFutures-DLB-HealthySpaces-V1.0.pdf',
                  metadata: '.PDF 1.3MB',
                },
              ]}
            />
          </Column>
        </Row>
      </Container>
    </Section>
    <Quote
      quote="Working with businesses and communities to sustainably create the healthiest, most efficient environments possible."
      img={'STEM-image-testimonial.jpg'}
      name={'Cristian'}
      title={'Virtual Field Trip Student Host'}
    />
  </Layout>
);

export default IndexPage;
